/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { SubmitClaimPayload, UpdateMeritCsPermissionsByMeritCsPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUser
   * @request GET:/api/user/
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUser = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetUserByParent
   * @request GET:/api/user/parent
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUserByParent = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/parent`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetUserByMeritCs
   * @request GET:/api/user/merit-cs
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUserByMeritCs = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/merit-cs`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetUserByServiceProvider
   * @request GET:/api/user/service-provider/{selectedOrgId}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUserByServiceProvider = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/service-provider/${selectedOrgId}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name SubmitClaim
   * @request POST:/api/user/claim
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  submitClaim = async (
    body: SubmitClaimPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/user/claim`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SetHasProvidedBankDetailsForParent
   * @request PATCH:/api/user/parent/set-bank-flag
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  setHasProvidedBankDetailsForParent = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/parent/set-bank-flag`,
      method: "PATCH",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name SetHasProvidedBankDetailsForServiceProvider
   * @request PATCH:/api/user/service-provider/{selectedOrgId}/set-bank-flag
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  setHasProvidedBankDetailsForServiceProvider = (
    selectedOrgId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        }
      | {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Single Review"
                | "Pending First Review"
                | "Pending Second Review"
                | "Pending Escalation Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" &
                    "Pending Single Review" &
                    "Pending First Review" &
                    "Pending Second Review" &
                    "Pending Escalation Review" &
                    "Pending Vendor Approval" &
                    "Accepted" &
                    "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        } & {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }),
      any
    >({
      path: `/api/user/service-provider/${selectedOrgId}/set-bank-flag`,
      method: "PATCH",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetAllMeritCsByMeritCs
   * @request GET:/api/user/merit-cs/all
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getAllMeritCsByMeritCs = (params: RequestParams = {}) =>
    this.request<
      {
        meritCSList: {
          email: string;
          id: number;
          manageClaims: boolean;
          manageEscalatedClaims: boolean;
          manageServiceProviders: boolean;
          manageUsers: boolean;
          type: "MeritCS";
        }[];
      },
      any
    >({
      path: `/api/user/merit-cs/all`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name UpdateMeritCsPermissionsByMeritCs
   * @request PUT:/api/user/merit-cs/{id}/permissions
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateMeritCsPermissionsByMeritCs = async (
    id: number,
    body: UpdateMeritCsPermissionsByMeritCsPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/user/merit-cs/${id}/permissions`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
