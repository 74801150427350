// Copyright 2023 Merit International Inc. All Rights Reserved

import { Platform } from "react-native";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { useUserStore } from "../store/userStore";
import type { WebRouteParams } from "../navigation/Routes";

export type UserTypeWithLoggedInDetails =
  | "meritCSLoggedInWeb"
  | "parentLoggedInNative"
  | "parentLoggedInWeb"
  | "preLogin"
  | "serviceProviderLoggedInWeb";

export const pathConfigMap: Record<keyof WebRouteParams, string> = {
  BankAlreadyLinkedScreen: "/bank-already-linked",
  BankLinkFailure: "/service-provider-connect-bank/failure",
  BankLinkSuccess: "/service-provider-connect-bank/success",
  ClaimApproved: "/review-claim/approved",
  ClaimRejected: "/review-claim/rejected",
  Dashboard: "/dashboard",
  MeritCSClaimDetails: "/merit-cs/claim",
  MeritCSClaimLookup: "/merit-cs/claim-lookup",
  MeritCSDashboard: "/merit-cs/dashboard",
  MeritCSEscalatedClaims: "/merit-cs/escalated-claims",
  MeritCSInsufficientPermissions: "/merit-cs/insufficient-permissions",
  MeritCSLogin: "/merit-cs",
  MeritCSManageServiceProviders: "/merit-cs/manage-service-providers",
  MeritCSManageUsers: "/merit-cs/manage-users",
  MeritCSNotEligible: "/merit-cs/not-eligible",
  MeritCSReviewClaims: "/merit-cs/review-claims",
  NotEligible: "/not-eligible",
  NotFound: "*",
  ParentLinkBankAccountScreen: "/parent/bank-account",
  SentForExternalReview: "/review-claim/sent-for-external-review",
  ServiceProvider: "/non-ace-service-provider",
  ServiceProviderClaimScreen: "/service-provider/claim",
  ServiceProviderDashboard: "/service-provider/dashboard",
  ServiceProviderLogin: "/service-provider",
  ServiceProviderNotEligible: "/service-provider/not-eligible",
  Splash: "/",
  SubmitNewClaim: "/submit-new-claim",
  UnauthenticatedReviewClaim: "/review-claim",
};

const {
  BankAlreadyLinkedScreen,
  BankLinkFailure,
  BankLinkSuccess,
  ClaimApproved,
  ClaimRejected,
  Dashboard,
  MeritCSClaimDetails,
  MeritCSClaimLookup,
  MeritCSEscalatedClaims,
  MeritCSInsufficientPermissions,
  MeritCSLogin,
  MeritCSManageServiceProviders,
  MeritCSManageUsers,
  MeritCSNotEligible,
  MeritCSReviewClaims,
  NotEligible,
  NotFound,
  ParentLinkBankAccountScreen,
  SentForExternalReview,
  ServiceProvider,
  ServiceProviderClaimScreen,
  ServiceProviderDashboard,
  ServiceProviderLogin,
  ServiceProviderNotEligible,
  Splash,
  SubmitNewClaim,
  UnauthenticatedReviewClaim,
} = pathConfigMap;

const unauthenticatedReviewScreens = {
  ClaimApproved,
  ClaimRejected,
  SentForExternalReview,
  UnauthenticatedReviewClaim,
};

const parentWebRouteLinking = {
  config: {
    screens: {
      BankAlreadyLinkedScreen,
      BankLinkFailure,
      BankLinkSuccess,
      Dashboard,
      NotEligible,
      NotFound,
      ParentLinkBankAccountScreen,
      ServiceProvider,
      SubmitNewClaim,
      ...unauthenticatedReviewScreens,
    },
  },
  prefixes: [""],
};

const parentNativeRouteLinking = {
  config: {
    screens: {
      BankAlreadyLinkedScreen,
      BankLinkFailure,
      BankLinkSuccess,
      Dashboard,
      NotEligible,
      NotFound,
      ParentLinkBankAccountScreen,
      ServiceProvider,
      SubmitNewClaim,
    },
  },
  prefixes: [""],
};

const meritCSWebRouteLinking = {
  config: {
    screens: {
      MeritCSDashboard: {
        screens: {
          MeritCSClaimDetails,
          MeritCSClaimLookup,
          MeritCSEscalatedClaims,
          MeritCSManageServiceProviders,
          MeritCSManageUsers,
          MeritCSReviewClaims,
        },
      },
      MeritCSInsufficientPermissions,
      MeritCSLogin,
      MeritCSNotEligible,
      NotFound,
      ...unauthenticatedReviewScreens,
    },
  },
  prefixes: [""],
};

const serviceProviderWebRouteLinking = {
  config: {
    screens: {
      BankAlreadyLinkedScreen,
      BankLinkFailure,
      BankLinkSuccess,
      NotFound,
      ServiceProviderClaimScreen,
      ServiceProviderDashboard,
      ServiceProviderLogin,
      ServiceProviderNotEligible,
      ...unauthenticatedReviewScreens,
    },
  },
  prefixes: [""],
};
const preLoginRouteLinking = {
  config: {
    screens: {
      MeritCSLogin,
      NotFound,
      ServiceProviderLogin,
      Splash,
      ...unauthenticatedReviewScreens,
    },
  },
  prefixes: [""],
};

export const useUserTypeWithLoggedInDetails = (): UserTypeWithLoggedInDetails => {
  const isServiceProviderSignedIn = useUserStore(_ => _.isServiceProviderSignedIn);
  const isMeritCSSignedIn = useUserStore(_ => _.isMeritCSSignedIn);
  const isParentSignedIn = useUserStore(_ => _.isParentSignedIn);

  const isParentSignedInWeb = isParentSignedIn && Platform.OS === "web";
  const isParentSignedInNative =
    isParentSignedIn && (Platform.OS === "android" || Platform.OS === "ios");

  if (isParentSignedInWeb) {
    return "parentLoggedInWeb";
  } else if (isParentSignedInNative) {
    return "parentLoggedInNative";
  } else if (isMeritCSSignedIn) {
    return "meritCSLoggedInWeb";
  } else if (isServiceProviderSignedIn) {
    return "serviceProviderLoggedInWeb";
  }

  return "preLogin";
};

export const useRoutesLinking = () => {
  const userTypeWithLoggedInDetails = useUserTypeWithLoggedInDetails();

  switch (userTypeWithLoggedInDetails) {
    case "meritCSLoggedInWeb":
      return meritCSWebRouteLinking;
    case "parentLoggedInNative":
      return parentNativeRouteLinking;
    case "parentLoggedInWeb":
      return parentWebRouteLinking;
    case "serviceProviderLoggedInWeb":
      return serviceProviderWebRouteLinking;
    case "preLogin":
      return preLoginRouteLinking;
    default:
      throw new UnreachableCaseError(userTypeWithLoggedInDetails);
  }
};
