/* eslint-disable functional/no-let */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Some } from "./Some";

export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>) => {
    if (Some(timeoutId)) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
