import { Button } from "../Button/Button";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { Table } from "../Table/Table.web";
import { formatCurrency } from "../../utils/FormatHelper";
import { useTheme } from "@merit/frontend-components";
import React, { useMemo } from "react";
import dayjs from "dayjs";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { SortDirection, TableColumn } from "../Table/types";

export type ColumnKeys =
  | "amount"
  | "awardId"
  | "childName"
  | "claimSubmissionDate"
  | "email"
  | "id"
  | "parentName"
  | "payTo"
  | "schoolYear"
  | "serviceProviderName"
  | "serviceProviderNumber"
  | "status"
  | "view";

type TableColumnClaimType = TableColumn & {
  readonly key: ColumnKeys;
};

type SortParams = {
  sortBy: ColumnKeys;
  sortOrder: SortDirection;
};

type Props = {
  readonly claims: GetClaimsResponse["claims"];
  readonly onSort?: ({ sortBy, sortOrder }: SortParams) => void;
  readonly defaultSort?: { key: ColumnKeys; direction: SortDirection };
};

export const ClaimsList = ({
  claims,
  defaultSort = { direction: "Descending", key: "id" },
  onSort,
}: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    bodyText: {
      fontSize: theme.fontSizes.s.fontSize,
      fontWeight: theme.fontWeights.semiBold,
      letterSpacing: 0.5,
      lineHeight: theme.fontSizes.s.lineHeight,
      paddingRight: 5,
    },
    centeredText: {
      margin: "auto",
    },
    centeredView: {
      alignItems: "center",
      height: "auto",
      justifyContent: "center",
    },
  });

  // Table columns
  const columns: readonly TableColumnClaimType[] = [
    {
      key: "id",
      onSort: sort => {
        onSort?.(sort);
      },
      sortable: true,
      style: styles.bodyText,
      title: "Claim ID",
      width: 120,
    },
    { key: "awardId", sortable: false, style: styles.bodyText, title: "Award ID", width: 200 },
    { key: "email", sortable: false, style: styles.bodyText, title: "Parent email", width: 200 },
    {
      key: "parentName",
      onSort: sort => {
        onSort?.(sort);
      },
      sortable: true,
      style: styles.bodyText,
      title: "Parent name",
      width: 200,
    },
    {
      key: "serviceProviderName",
      onSort: sort => {
        onSort?.(sort);
      },
      sortable: true,
      style: styles.bodyText,
      title: "Service provider name",
      width: 200,
    },
    { key: "payTo", sortable: false, style: styles.bodyText, title: "Pay to", width: 100 },
    {
      key: "claimSubmissionDate",
      sortable: false,
      style: styles.bodyText,
      title: "Claim submission date",
      width: 180,
    },
    {
      key: "childName",
      onSort: sort => {
        onSort?.(sort);
      },
      sortable: true,
      style: styles.bodyText,
      title: "Student name",
      width: 200,
    },
    {
      key: "amount",
      onSort: sort => {
        onSort?.(sort);
      },
      sortable: true,
      style: styles.bodyText,
      title: "Claim amount",
      width: 120,
    },
    { key: "schoolYear", sortable: false, style: styles.bodyText, title: "Claim year", width: 140 },
    {
      key: "serviceProviderNumber",
      sortable: false,
      style: [styles.bodyText],
      title: "Service provider number",
      width: 200,
    },
    { key: "status", sortable: false, style: styles.bodyText, title: "Status", width: 200 },
    {
      key: "view",
      sortable: false,
      sticky: true,
      style: [styles.bodyText],
      title: "Review",
      width: 150,
    },
  ];

  // Table data
  const claimsTableData = useMemo(
    () =>
      claims.map(claim => ({
        amount: claim.amount,
        awardId: claim.child.awardId,
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        claimSubmissionDate: dayjs(claim.createdAt).format("MM/DD/YYYY"),
        email: Some(claim.parent) && claim.parent.email,
        id: claim.id,
        parentName: Some(claim.parent) && `${claim.parent.firstName} ${claim.parent.lastName}`,
        payTo: claim.payTo,
        schoolYear: Some(claim.child.schoolYear)
          ? `${claim.child.schoolYear} - ${claim.child.schoolYear + 1}`
          : "NA",
        serviceProviderName: Some(claim.serviceProvider)
          ? claim.serviceProvider.name
          : claim.nonAceServiceProvider?.name,
        serviceProviderNumber: claim.serviceProvider?.number ?? "NA",
        status: claim.status,
        view: (
          <View style={{ paddingRight: 24, width: 120 }}>
            <Button
              customContent={<Text style={{ fontWeight: "600" }}>View</Text>}
              onPress={() => {
                window.open(`/merit-cs/claim?claimId=${claim.id}`, "_blank", "noopener,noreferrer");
              }}
              size="small"
              testID={`${claim.id}-ViewButton-ClaimsList`}
              type="secondary"
            />
          </View>
        ),
      })),
    [claims],
  );

  return (
    <>
      <Table
        columns={columns}
        data={claimsTableData}
        defaultSort={defaultSort}
        emptyComponent={
          <View style={styles.centeredView}>
            <Text>You have no claims</Text>
          </View>
        }
        render={(data, key) => (
          <Text style={[styles.bodyText, { fontWeight: theme.fontWeights.normal }]}>
            {key === "amount" ? formatCurrency(data[key]) : data[key]}
          </Text>
        )}
      />
    </>
  );
};
