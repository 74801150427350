import { ClaimFilters } from "./ClaimFilters";
import { ClaimsList } from "../../components/ClaimsList";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { Pagination } from "../../components/Pagination/Pagination";
import { Some } from "../../utils/Some";
import { View } from "react-native";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import type { ClaimFiltersType } from "./ClaimFilters";
import type { ColumnKeys } from "../../components/ClaimsList/ClaimsList";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { MeritCSDrawerRouteParams } from "../../navigation";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteProp } from "@react-navigation/native";
import type { SortDirection } from "../../components/Table/types";

type PaginationState = {
  readonly pageNumber: number;
  readonly limit: number;
  readonly total: number;
  readonly totalPages: number;
};

type ParamsType = MeritCSDrawerRouteParams["MeritCSClaimLookup"];

export const ClaimLookupScreen = () => {
  const { claimClient } = useApi();
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();
  const { params } = useRoute<RouteProp<MeritCSDrawerRouteParams, "MeritCSClaimLookup">>();

  const [claims, setClaims] = useState<GetClaimsResponse["claims"]>([]);

  const [claimFilters, setClaimFilters] = useState<ClaimFiltersType>({
    awardId: "",
    childFirstName: "",
    childLastName: "",
    claimAmount: "",
    claimId: "",
    claimStatus: undefined,
    claimSubmissionDate: undefined,
    parentEmail: "",
    parentFirstName: "",
    parentLastName: "",
    payTo: undefined,
    serviceProviderName: "",
    serviceProviderNumber: "",
    sortBy: "id",
    sortOrder: "Descending",
  });

  const pageNumberFromRouteParams = Some(params) && Some(params.pageNumber) ? params.pageNumber : 1;

  const [paginationState, setPaginationState] = useState<PaginationState>({
    limit: 25,
    pageNumber: Number(pageNumberFromRouteParams),
    total: 0,
    totalPages: 0,
  });

  const getClaims = useCallback(
    async (applyFilters = false) => {
      setIsLoading(true);
      try {
        if (
          applyFilters &&
          Some(params.serviceProviderNumber) &&
          params.serviceProviderNumber !== "" &&
          params.serviceProviderNumber.length !== 6
        ) {
          showToast({ message: "Service provider number must be 6 digits", type: "danger" });

          return;
        }

        const getFilters = () => {
          if (None(params)) {
            return undefined;
          }

          const isNonEmptyString = (value: string | undefined) =>
            Some(value) && value.trim() !== "";
          const isNonZeroNumber = (value: string | undefined) =>
            !isNaN(Number(value)) && Number(value) !== 0;

          const {
            awardId,
            childFirstName,
            childLastName,
            claimAmount,
            claimId,
            claimStatus,
            claimSubmissionDate,
            parentEmail,
            parentFirstName,
            parentLastName,
            payTo,
            serviceProviderName,
            serviceProviderNumber,
            sortBy,
            sortOrder,
          } = params;

          return {
            sortBy,
            sortOrder: sortOrder === "Ascending" ? "asc" : "desc",
            ...(isNonEmptyString(childFirstName) && {
              childFirstName,
            }),
            ...(isNonEmptyString(childLastName) && {
              childLastName,
            }),
            ...(isNonEmptyString(parentFirstName) && {
              parentFirstName,
            }),
            ...(isNonEmptyString(parentLastName) && {
              parentLastName,
            }),
            ...(isNonEmptyString(parentEmail) && {
              parentEmail,
            }),
            ...(isNonEmptyString(awardId) && {
              awardId,
            }),
            ...(isNonZeroNumber(claimAmount) && {
              claimAmount: Number(claimAmount),
            }),
            ...(isNonEmptyString(claimSubmissionDate) && {
              claimSubmissionDate,
            }),
            ...(isNonEmptyString(payTo) && {
              payTo,
            }),
            ...(isNonEmptyString(serviceProviderName) && {
              serviceProviderName,
            }),
            ...(Some(claimStatus) && { statuses: claimStatus }),
            ...(isNonZeroNumber(claimId) && { claimId: Number(claimId) }),
            ...(isNonEmptyString(serviceProviderNumber) && {
              serviceProviderNumber,
            }),
          };
        };

        const response = await claimClient.getClaims({
          limit: paginationState.limit,
          page: paginationState.pageNumber,
          ...(applyFilters && getFilters()),
        });
        if (response.success) {
          setClaims(response.data.claims);
          setPaginationState(prev => ({
            ...prev,
            total: response.data.total,
            totalPages: response.data.totalPages,
          }));
        } else {
          showToast({ message: response.message, type: "danger" });
        }
      } catch (error: unknown) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [claimClient, errorHandler, paginationState.limit, paginationState.pageNumber, params],
  );

  const getNonEmptyParamFilters = (filters: ParamsType) =>
    Object.keys(filters).reduce((result, key) => {
      const value = filters[key as keyof ParamsType];
      if (typeof value === "string" && value.trim() !== "" && key !== "pageNumber") {
        return { ...result, [key]: value };
      }

      return result;
    }, {});

  useEffect(() => {
    setClaimFilters(prev => ({
      ...prev,
      ...getNonEmptyParamFilters(params),
    }));

    getClaims(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.pageNumber, params]);

  useEffect(() => {
    const refreshClaimsOnVisibility = () => {
      if (document.visibilityState === "visible") {
        getClaims(true);
      }
    };

    document.addEventListener("visibilitychange", refreshClaimsOnVisibility);

    return () => {
      document.removeEventListener("visibilitychange", refreshClaimsOnVisibility);
    };
  }, [getClaims]);

  const resetFilters = () => {
    setPaginationState(prev => ({
      ...prev,
      pageNumber: 1,
    }));
    setClaimFilters({
      awardId: "",
      childFirstName: "",
      childLastName: "",
      claimAmount: "",
      claimId: "",
      claimStatus: undefined,
      claimSubmissionDate: undefined,
      parentEmail: "",
      parentFirstName: "",
      parentLastName: "",
      payTo: undefined,
      serviceProviderName: "",
      serviceProviderNumber: "",
      sortBy: "id",
      sortOrder: "Descending",
    });
  };

  // the column header does not have a way to return the direction once it has updated on click,
  // so we are making use of the state of sorting that is available before the update operation
  const getCurrentSortDirectionFromTable = {
    Ascending: "Descending",
    Descending: "Ascending",
  } as const;

  type SortParams = {
    sortBy: ColumnKeys;
    sortOrder: SortDirection;
  };

  const onSort = ({ sortBy, sortOrder }: SortParams) => {
    setClaimFilters(prev => ({ ...prev, sortBy, sortOrder }));
    navigation.navigate("MeritCSClaimLookup", {
      ...getNonEmptyParamFilters(claimFilters),
      pageNumber: 1,
      sortBy,
      sortOrder: getCurrentSortDirectionFromTable[sortOrder],
    });
  };

  return (
    <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
      <ClaimFilters
        claimFilters={claimFilters}
        onApply={() => {
          setPaginationState(prev => ({
            ...prev,
            pageNumber: 1,
          }));
          navigation.navigate("MeritCSClaimLookup", {
            ...getNonEmptyParamFilters(claimFilters),
            pageNumber: 1,
            sortBy: claimFilters.sortBy,
            sortOrder: claimFilters.sortOrder,
          });
        }}
        onChange={update => {
          setClaimFilters(prev => ({
            ...prev,
            ...update,
          }));
        }}
        onReset={() => {
          resetFilters();
          navigation.navigate("MeritCSClaimLookup", {
            pageNumber: 1,
            sortBy: "id",
            sortOrder: "Descending",
          });
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <ClaimsList
          claims={claims}
          defaultSort={{
            direction: claimFilters.sortOrder,
            key: claimFilters.sortBy,
          }}
          onSort={onSort}
        />
      )}
      {claims.length > 0 && (
        <Pagination
          currentPage={paginationState.pageNumber}
          onPageChange={(pageNumber: number) => {
            navigation.navigate("MeritCSClaimLookup", {
              ...(Some(params) && getNonEmptyParamFilters(params)),
              pageNumber,
              sortBy: claimFilters.sortBy,
              sortOrder: claimFilters.sortOrder,
            });
            setPaginationState(prev => ({
              ...prev,
              pageNumber,
            }));
          }}
          pageSize={paginationState.limit}
          totalCount={paginationState.total}
          totalPages={paginationState.totalPages}
        />
      )}
    </View>
  );
};
