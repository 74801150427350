import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { ErrorText } from "../ErrorText";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import type { ClaimLookupProps } from "./type";
import type { UpdateClaimPayToPayload } from "../../__generated__/api/data-contracts";

export const UpdateClaimPayTo = ({ claimDetails, onUpdate }: ClaimLookupProps) => {
  const toast = useToast();
  const { theme } = useTheme();
  const { claimClient } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showChangePayToModal, setShowChangePayToModal] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [updateClaimPayToPayload, setUpdateClaimPayToPayload] = useState<UpdateClaimPayToPayload>();
  const [payToValue, setPayToValue] = useState<string | undefined>();

  const {
    claim: { id: claimId, parent, serviceProvider },
  } = claimDetails;
  const userCanBePaid = parent.hasProvidedBankDetails && parent.hasHealthyBankConnection;
  const serviceProviderCanBePaid =
    Some(serviceProvider) &&
    serviceProvider.hasProvidedBankDetails &&
    serviceProvider.hasHealthyBankConnection;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      flexDirection: "column",
    },
    inputContainer: {
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: theme.spacing.l,
      paddingTop: 6,
    },
    inputWrapper: {
      width: 250,
    },
    submitButton: {
      width: 84,
      zIndex: -1,
    },
  });

  const handlePayToValue = (payTo: number | string | undefined) => {
    if (typeof payTo === "string" || payTo === undefined) {
      setPayToValue(payTo);
    }
  };

  const validationSchema = Yup.object().shape({
    payTo: Yup.string().test({
      test: (value, ctx) => {
        if (Some(value)) {
          if (value === "User" && !userCanBePaid) {
            return ctx.createError({ message: "User has not connected with bank account" });
          } else if (value === "Vendor" && !serviceProviderCanBePaid) {
            return ctx.createError({
              message: "Service provider has not connected with bank account",
            });
          }
        } else if (None(value)) {
          return ctx.createError({ message: "Please select a valid Pay To" });
        }

        return true;
      },
    }),
  });

  const updateClaimPayTo = async () => {
    if (None(claimId)) {
      throw new Error("Somehow trying to update claim Pay To without valid claim id");
    }

    if (None(updateClaimPayToPayload)) {
      throw new Error("Somehow trying to update claim Pay To without valid claim payload");
    }
    setIsLoading(true);
    try {
      const response = await claimClient.updateClaimPayTo(claimId, updateClaimPayToPayload);
      if (response.success) {
        toast.show(<Text>Successfully updated the claim Pay To</Text>, {
          placement: "top",
          type: "success",
        });
        onUpdate();
      } else {
        toast.show(<Text>{response.message}</Text>, {
          placement: "top",
          type: "danger",
        });
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
    setIsLoading(false);
    setShowChangePayToModal(false);
    setPayToValue(undefined);
  };

  const payToLabel = (payTo: string | undefined) => {
    if (payTo === undefined || payTo === "") {
      return "";
    }

    return payTo === "User" ? "Student (Parent/Guardian)" : "Service Provider";
  };

  return (
    <SafeAreaView style={{ backgroundColor: theme.colors.background.white, flex: 2 }}>
      <View style={styles.container}>
        <>
          {Some(claimDetails) && (
            <>
              {isLoading ? (
                <>
                  <View style={{ minWidth: 160 }}>
                    <Loading />
                  </View>
                </>
              ) : (
                <>
                  <View>
                    <View>
                      {claimDetails.claim.status === "Rejected" ? (
                        <View
                          style={{
                            borderTopColor: theme.colors.border.default,
                            borderTopWidth: 1,
                            paddingTop: theme.spacing.l,
                          }}
                        >
                          <ErrorText
                            error={`You can not update Pay To for claims that are Rejected. The status of this claim is ${claimDetails.claim.status}.`}
                          />
                        </View>
                      ) : (
                        <Formik
                          initialValues={{
                            payTo: Some(updateClaimPayToPayload)
                              ? updateClaimPayToPayload.payTo
                              : "Vendor",
                          }}
                          onSubmit={values => {
                            setShowChangePayToModal(true);
                            setUpdateClaimPayToPayload({
                              payTo: values.payTo,
                            });
                          }}
                          validateOnChange
                          validationSchema={validationSchema}
                        >
                          {({ handleSubmit, setFieldValue }) => (
                            <>
                              <View style={styles.inputContainer}>
                                <View style={styles.inputWrapper}>
                                  <Select
                                    disabled={false}
                                    onSelectOption={option => {
                                      setFieldValue("payTo", option?.value);
                                      handlePayToValue(option?.value);
                                    }}
                                    options={[
                                      {
                                        label: "Student (Parent/Guardian)",
                                        value: "User",
                                      },
                                      {
                                        label: "Service Provider",
                                        value: "Vendor",
                                      },
                                    ].filter(_ => _.value !== claimDetails.claim.payTo)}
                                  />
                                  <ErrorMessage name="payTo">
                                    {error => <ErrorText error={error} />}
                                  </ErrorMessage>
                                </View>
                              </View>

                              <View style={styles.submitButton}>
                                <Button
                                  disabled={!Some(payToValue)}
                                  onPress={handleSubmit}
                                  size="small"
                                  text="Update"
                                  type="primary"
                                />
                              </View>
                            </>
                          )}
                        </Formik>
                      )}
                    </View>

                    <ConfirmationModal
                      isVisible={showChangePayToModal}
                      onCancel={() => {
                        setShowChangePayToModal(false);
                        setUpdateClaimPayToPayload(undefined);
                      }}
                      onConfirm={() => {
                        updateClaimPayTo();
                      }}
                      text={
                        Some(claimId)
                          ? `You're changing the Pay To of the Claim ID ${claimId} from ${payToLabel(
                              claimDetails.claim.payTo,
                            )} to ${Boolean(updateClaimPayToPayload?.payTo) && payToLabel(updateClaimPayToPayload?.payTo)}. Do you want to proceed?`
                          : ""
                      }
                    />
                  </View>
                </>
              )}
            </>
          )}
        </>
      </View>
    </SafeAreaView>
  );
};
