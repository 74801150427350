import { Button, Checkbox, useTheme } from "@merit/frontend-components";
import { CustomConfirmModal } from "../../components/CustomConfirmModal";
import { Image, StyleSheet, Text, View } from "react-native";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { Table } from "../../components/Table/Table.web";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import type { GetAllMeritCsByMeritCsResponse } from "../../__generated__/api/UserRoute";
import type { TableColumn } from "../../components/Table/types";

type PermissionKeys =
  | "manageClaims"
  | "manageEscalatedClaims"
  | "manageServiceProviders"
  | "manageUsers";

type TableKeys = PermissionKeys | "email" | "save";

type TableColumnClaimType = TableColumn & {
  readonly key: TableKeys;
};

export const ManageUsersScreen = () => {
  const { theme } = useTheme();
  const { userClient } = useApi();
  const { errorHandler } = useDefaultErrorHandler();

  const styles = StyleSheet.create({
    bodyText: {
      fontSize: theme.fontSizes.s.fontSize,
      fontWeight: theme.fontWeights.semiBold,
      letterSpacing: 0.5,
      lineHeight: theme.fontSizes.s.lineHeight,
      paddingRight: 5,
    },
    centeredContent: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "center",
    },
    container: {
      backgroundColor: theme.colors.background.white,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    permissionText: {
      alignItems: "center",
      display: "flex",
      lineHeight: theme.spacing.xxl,
    },
  });

  const columns: readonly TableColumnClaimType[] = [
    { key: "email", sortable: false, style: styles.bodyText, title: "Email", width: 200 },
    {
      key: "manageServiceProviders",
      sortable: false,
      style: styles.bodyText,
      title: "Manage Service Providers",
      width: 200,
    },
    {
      key: "manageClaims",
      sortable: false,
      style: styles.bodyText,
      title: "Manage Claims",
      width: 200,
    },
    {
      key: "manageEscalatedClaims",
      sortable: false,
      style: styles.bodyText,
      title: "Manage Escalated Claims",
      width: 200,
    },
    {
      key: "manageUsers",
      sortable: false,
      style: styles.bodyText,
      title: "Manage Users",
      width: 200,
    },
    {
      key: "save",
      sortable: false,
      sticky: true,
      style: styles.bodyText,
      title: "Save",
      width: 150,
    },
  ];

  const [meritCSList, setMeritCsList] = useState<GetAllMeritCsByMeritCsResponse["meritCSList"]>();
  const [updatedMeritCSList, setUpdatedMeritCSList] =
    useState<GetAllMeritCsByMeritCsResponse["meritCSList"]>();
  const [selectedMeritCS, setSelectedMeritCS] =
    useState<GetAllMeritCsByMeritCsResponse["meritCSList"][number]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const getAllMeritCS = useCallback(async () => {
    try {
      const { meritCSList: response } = await userClient.getAllMeritCsByMeritCs();
      setMeritCsList(response);
      setUpdatedMeritCSList(response);
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  }, [errorHandler, userClient]);

  useEffect(() => {
    getAllMeritCS();
  }, [getAllMeritCS]);

  if (isLoading || None(meritCSList) || None(updatedMeritCSList)) {
    return (
      <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
        <Loading />
      </View>
    );
  }

  const updateMeritCS = async () => {
    if (None(selectedMeritCS)) {
      throw new Error("Can not proceed without selected merit cs");
    }

    const { id, manageClaims, manageEscalatedClaims, manageServiceProviders, manageUsers } =
      selectedMeritCS;
    setIsLoading(true);

    try {
      await userClient.updateMeritCsPermissionsByMeritCs(id, {
        manageClaims,
        manageEscalatedClaims,
        manageServiceProviders,
        manageUsers,
      });

      showToast({ message: "Merit CS permissions updated successfully" });
      setShowConfirmationModal(false);
      setSelectedMeritCS(undefined);

      getAllMeritCS();
    } catch (error) {
      errorHandler(error);
    }
  };

  const updateMeritCSList = (checked: boolean, index: number, permissionType: PermissionKeys) => {
    setUpdatedMeritCSList(
      updatedMeritCSList.map((item, idx) =>
        idx === index ? { ...item, [permissionType]: checked } : item,
      ),
    );
  };

  const getPermissionsIcon = (value: boolean) =>
    value ? (
      <Image source={theme.icons.checkmarkSmallAction} style={{ height: 24, width: 24 }} />
    ) : (
      <Image source={theme.icons.closeSmallDefault} style={{ height: 24, width: 24 }} />
    );

  const claimsTableData = meritCSList.map((meritCS, index) => ({
    email: meritCS.email,
    id: meritCS.id,
    manageClaims: (
      <View style={{ ...styles.centeredContent, marginLeft: 40 }}>
        <Checkbox
          defaultChecked={meritCS.manageClaims}
          onChange={checked => {
            updateMeritCSList(checked, index, "manageClaims");
          }}
        />
      </View>
    ),
    manageEscalatedClaims: (
      <View style={{ ...styles.centeredContent, marginLeft: 80 }}>
        <Checkbox
          defaultChecked={meritCS.manageEscalatedClaims}
          onChange={checked => {
            updateMeritCSList(checked, index, "manageEscalatedClaims");
          }}
        />
      </View>
    ),
    manageServiceProviders: (
      <View style={{ ...styles.centeredContent, marginLeft: 80 }}>
        <Checkbox
          defaultChecked={meritCS.manageServiceProviders}
          onChange={checked => {
            updateMeritCSList(checked, index, "manageServiceProviders");
          }}
        />
      </View>
    ),
    manageUsers: (
      <View style={{ ...styles.centeredContent, marginLeft: 40 }}>
        <Checkbox
          defaultChecked={meritCS.manageUsers}
          onChange={checked => {
            updateMeritCSList(checked, index, "manageUsers");
          }}
        />
      </View>
    ),
    save: (
      <View style={{ width: 80 }}>
        <Button
          customContent={<Text style={{ fontWeight: "600" }}>Save</Text>}
          onPress={() => {
            setShowConfirmationModal(true);
            setSelectedMeritCS(updatedMeritCSList[index]);
          }}
          size="small"
          type="secondary"
        />
      </View>
    ),
  }));

  return (
    <>
      <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
        <View style={styles.container}>
          <Table
            columns={columns}
            data={claimsTableData}
            defaultSort={{ direction: "Descending", key: "id" }}
            emptyComponent={<Text>Data not available</Text>}
            render={(data, key) => (
              <Text style={{ fontWeight: theme.fontWeights.normal }}>{data[key]}</Text>
            )}
          />
        </View>
      </View>
      {Some(selectedMeritCS) && (
        <CustomConfirmModal
          body={
            <View style={{ width: "100%" }}>
              <Text>
                Changing Merit CS permissions for{" "}
                <Text style={{ fontWeight: theme.fontWeights.semiBold }}>
                  {selectedMeritCS.email}
                </Text>
              </Text>
              <Text style={{ ...styles.permissionText, marginTop: theme.spacing.m }}>
                - Manage Service Providers:{" "}
                {getPermissionsIcon(selectedMeritCS.manageServiceProviders)}
              </Text>
              <Text style={styles.permissionText}>
                - Manage Escalated Claims:{" "}
                {getPermissionsIcon(selectedMeritCS.manageEscalatedClaims)}
              </Text>
              <Text style={styles.permissionText}>
                - Manage Claims: {getPermissionsIcon(selectedMeritCS.manageClaims)}
              </Text>
              <Text style={styles.permissionText}>
                - Manage Users: {getPermissionsIcon(selectedMeritCS.manageUsers)}
              </Text>
            </View>
          }
          isVisible={showConfirmationModal}
          modalWidth="40%"
          onCancel={() => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => {
            updateMeritCS();
          }}
          title="Are you sure?"
        />
      )}
    </>
  );
};
