import { Button, useTheme } from "@merit/frontend-components";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { usePagination } from "../../hooks/usePagination";

type PaginationProps = {
  readonly onPageChange: (pageNumber: number) => void;
  readonly totalCount: number;
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalPages: number;
};

export const Pagination = ({
  currentPage,
  onPageChange,
  pageSize,
  totalCount,
  totalPages,
}: PaginationProps) => {
  const { theme } = useTheme();

  const disableNext = currentPage >= totalPages;
  const disablePrev = currentPage <= 1;

  const styles = StyleSheet.create({
    button: {
      marginHorizontal: theme.spacing.xxs,
      minWidth: 32,
      width: "auto",
    },
    buttonContainer: {
      flexDirection: "row",
    },
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: theme.spacing.xl,
      paddingHorizontal: theme.spacing.xl,
      paddingTop: theme.spacing.l,
    },
  });

  const paginationRange = usePagination({
    currentPage,
    pageSize,
    totalCount,
  });

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <Button
          disabled={disablePrev}
          iconLeft={disablePrev ? "chevronLeftMediumDisabled" : "chevronLeftMediumAction"}
          onPress={() => {
            onPageChange(currentPage - 1);
          }}
          shape="square"
          size="small"
          style={styles.button}
          testID="previousButton-Pagination"
          type="secondary"
        />

        {Some(paginationRange) &&
          paginationRange.map(pageNumber => {
            // If the pageItem is a null, render the dots
            if (pageNumber === null) {
              return (
                <Text key={pageNumber} style={{ paddingTop: theme.spacing.s }}>
                  ...
                </Text>
              );
            }

            // Render our Page Pills
            return (
              <Button
                key={pageNumber}
                onPress={() => {
                  onPageChange(pageNumber);
                }}
                shape="square"
                size="small"
                style={styles.button}
                testID={`${pageNumber}-Button-Pagination`}
                text={String(pageNumber)}
                type={pageNumber === currentPage ? "primary" : "secondary"}
              />
            );
          })}

        <Button
          disabled={disableNext}
          iconLeft={disableNext ? "chevronRightMediumDisabled" : "chevronRightMediumAction"}
          onPress={() => {
            onPageChange(currentPage + 1);
          }}
          shape="square"
          size="small"
          style={styles.button}
          testID="nextButton-Pagination"
          type="secondary"
        />
      </View>
    </View>
  );
};
