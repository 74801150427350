import { Button, Collapse, TextInput, useTheme } from "@merit/frontend-components";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { Select } from "../../components/Select";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { TypeaheadDropdown } from "../../components/TypeaheadDropdown";
import { useApi } from "../../services/useApi";
import { useState } from "react";
import { useToast } from "react-native-toast-notifications";
import type { ColumnKeys } from "../../components/ClaimsList/ClaimsList";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { SortDirection } from "../../components/Table/types";

type ClaimStatus = GetClaimsResponse["claims"][number]["status"];

export type ClaimFiltersType = {
  readonly childFirstName: string;
  readonly childLastName: string;
  readonly claimId: string;
  readonly parentFirstName: string;
  readonly parentLastName: string;
  readonly parentEmail: string;
  readonly awardId: string;
  readonly claimStatus: ClaimStatus | undefined;
  readonly serviceProviderName: string;
  readonly serviceProviderNumber: string;
  readonly claimSubmissionDate: string | undefined;
  readonly claimAmount: string;
  readonly payTo: string | undefined;
  readonly sortBy: ColumnKeys;
  readonly sortOrder: SortDirection;
};

type ClaimStatusOptions = {
  readonly label: ClaimStatus | "Select...";
  readonly value: ClaimStatus | undefined;
};

type PayToOptions = {
  readonly label: string;
  readonly value: string | undefined;
};

type Props = {
  readonly onApply: () => void;
  readonly claimFilters: ClaimFiltersType;
  readonly onChange: (update: Record<string, string | undefined>) => void;
  readonly onReset: () => void;
};

type Item = {
  name: string;
  value: string;
};

export const ClaimFilters = ({ claimFilters, onApply, onChange, onReset }: Props) => {
  const { theme } = useTheme();
  const { serviceProviderClient } = useApi();
  const toast = useToast();
  const [isFocusedClaimSubmitDatePicker, setIsFocusedClaimSubmitDatePicker] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<Item[]>([]);

  const styles = StyleSheet.create({
    accordionBodyWrapper: {
      marginLeft: 25,
      marginTop: -30,
    },
    accordionTitleWrapper: {
      flexDirection: "row",
      paddingBottom: 3.5,
      paddingTop: 3.5,
      paddingVertical: theme.spacing.l,
    },
    accordionWrapper: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      height: "auto",
      padding: 0,
      zIndex: 1,
    },
    buttonContainer: {
      alignItems: "flex-end",
      borderLeftColor: theme.colors.background.default,
      borderLeftWidth: 2,
      flexDirection: "row",
      gap: theme.spacing.m,
      justifyContent: "flex-end",
      marginBottom: 0,
      marginLeft: 15,
      width: 255,
    },
    filterItem: {
      width: 250,
    },
    filtersContainer: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing.l,
    },
    filtersRow: {
      alignContent: "flex-end",
      flexDirection: "row",
      gap: 24,
      justifyContent: "flex-start",
      margin: theme.spacing.s,
    },
    inputFieldsContainer: {
      marginRight: theme.spacing.l,
    },
  });

  const claimStatusOptions: ClaimStatusOptions[] = [
    {
      label: "Select...",
      value: undefined,
    },
    {
      label: "Pending Single Review",
      value: "Pending Single Review",
    },
    {
      label: "Pending First Review",
      value: "Pending First Review",
    },
    {
      label: "Pending Second Review",
      value: "Pending Second Review",
    },
    {
      label: "Pending Escalation Review",
      value: "Pending Escalation Review",
    },
    {
      label: "Pending Vendor Approval",
      value: "Pending Vendor Approval",
    },
    {
      label: "Accepted",
      value: "Accepted",
    },
    {
      label: "Rejected",
      value: "Rejected",
    },
  ];

  const payToOptions: PayToOptions[] = [
    {
      label: "Select...",
      value: undefined,
    },
    {
      label: "Student (Parent/Guardian)",
      value: "User",
    },
    {
      label: "Service Provider",
      value: "Vendor",
    },
  ];

  const searchServiceProviders = async (searchTerm: string) => {
    const response = await serviceProviderClient.searchServiceProviders({
      name: searchTerm,
    });
    if (response.success) {
      const options = response.data.serviceProviders.map(sp => ({
        name: sp.name,
        value: sp.name,
      }));
      setDropdownOptions(options);
    } else {
      toast.show(<Text>{response.message}</Text>, {
        placement: "top",
        type: "danger",
      });
    }
  };

  return (
    <View style={styles.accordionWrapper}>
      <Collapse
        children={
          <View style={styles.accordionBodyWrapper}>
            <View style={styles.filtersRow}>
              <View style={styles.filterItem}>
                <TextInput
                  label="Child First Name"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ childFirstName: value });
                  }}
                  placeholder="Child First Name"
                  size="medium"
                  value={claimFilters.childFirstName}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Child Last Name"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ childLastName: value });
                  }}
                  placeholder="Child Last Name"
                  size="medium"
                  value={claimFilters.childLastName}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Claim Amount"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ claimAmount: value });
                  }}
                  placeholder="Claim Amount"
                  size="medium"
                  value={claimFilters.claimAmount}
                />
              </View>
            </View>
            <View style={[styles.filtersRow, { zIndex: 1 }]}>
              <View style={styles.filterItem}>
                <TextInput
                  label="Parent First Name"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ parentFirstName: value });
                  }}
                  placeholder="Parent First Name"
                  size="medium"
                  value={claimFilters.parentFirstName}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Parent Last Name"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ parentLastName: value });
                  }}
                  placeholder="Parent Last Name"
                  size="medium"
                  value={claimFilters.parentLastName}
                />
              </View>
              <View style={styles.filterItem}>
                <Select
                  label="Claim Status"
                  onSelectOption={option => {
                    if (Some(option)) {
                      onChange({ claimStatus: option.value as ClaimStatus });
                    }
                  }}
                  options={[...claimStatusOptions]}
                  selectedValue={claimFilters.claimStatus}
                />
              </View>
            </View>
            <View style={styles.filtersRow}>
              <View style={styles.filterItem}>
                <TypeaheadDropdown
                  label="Service Provider Name"
                  onChange={text => {
                    onChange({ serviceProviderName: text });
                  }}
                  onSearch={searchTerm => {
                    searchServiceProviders(searchTerm);
                  }}
                  onSelect={option => {
                    onChange({ serviceProviderName: option.name });
                  }}
                  options={dropdownOptions}
                  placeholder="Service Provider Name"
                  value={claimFilters.serviceProviderName}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Service Provider Number"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ serviceProviderNumber: value });
                  }}
                  placeholder="Service Provider Number"
                  size="medium"
                  value={claimFilters.serviceProviderNumber}
                />
              </View>
              <View style={styles.filterItem}>
                <Select
                  label="Pay To"
                  onSelectOption={option => {
                    if (Some(option)) {
                      onChange({ payTo: option.value as ClaimStatus });
                    }
                  }}
                  options={[...payToOptions]}
                  selectedValue={claimFilters.payTo}
                />
              </View>
            </View>
            <View style={[styles.filtersRow, { zIndex: isFocusedClaimSubmitDatePicker ? 3 : -1 }]}>
              <View style={styles.filterItem}>
                <Text style={{ marginBottom: 5 }}>Claim Submission Date</Text>
                <DatePicker
                  maxDate={new Date()}
                  onBlur={() => {
                    setIsFocusedClaimSubmitDatePicker(false);
                  }}
                  onChange={date => {
                    onChange({ claimSubmissionDate: date });
                  }}
                  onFocus={() => {
                    setIsFocusedClaimSubmitDatePicker(true);
                  }}
                  placeholder="Claim Submission Date"
                  value={
                    Some(claimFilters.claimSubmissionDate)
                      ? new Date(claimFilters.claimSubmissionDate)
                      : undefined
                  }
                />
              </View>
            </View>
          </View>
        }
        renderHeader={
          <View style={styles.accordionTitleWrapper}>
            <View style={styles.filtersRow}>
              <View style={styles.filterItem}>
                <TextInput
                  label="Claim ID"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ claimId: value });
                  }}
                  placeholder="Claim ID"
                  size="medium"
                  value={claimFilters.claimId}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Award ID"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ awardId: value });
                  }}
                  placeholder="Award ID"
                  size="medium"
                  value={claimFilters.awardId}
                />
              </View>
              <View style={styles.filterItem}>
                <TextInput
                  label="Parent Email"
                  leftIcon="searchSmallSubdued"
                  onChangeText={value => {
                    onChange({ parentEmail: value });
                  }}
                  placeholder="Parent Email"
                  size="medium"
                  value={claimFilters.parentEmail}
                />
              </View>
              <View style={styles.buttonContainer}>
                <Button onPress={onApply} size="medium" text="Apply" type="primary" />
                <Button
                  onPress={() => {
                    onReset();
                  }}
                  size="medium"
                  text="Reset"
                  type="secondary"
                />
              </View>
            </View>
          </View>
        }
        type="custom"
      />
    </View>
  );
};
