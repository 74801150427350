import { Button } from "./Button";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useTheme } from "@merit/frontend-components";
import CloseIcon from "../../assets/icons/close_l_action.png";
import React from "react";
import type { DimensionValue } from "react-native";

type Props = {
  readonly isVisible: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly title: React.ReactNode | string;
  readonly body: React.ReactNode | string;
  readonly customButton?: React.ReactNode;
  readonly modalWidth?: DimensionValue;
};

export const CustomConfirmModal = ({
  body,
  customButton,
  isVisible,
  modalWidth,
  onCancel,
  onConfirm,
  title,
}: Props) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const { theme } = useTheme();

  const getModalWidth = (): DimensionValue => {
    if (Some(modalWidth)) {
      return modalWidth;
    }

    if (isDesktopOrLarger) {
      return "50%";
    }

    return "95%";
  };

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.spacing.xs,
      padding: theme.spacing.xxl,
      width: getModalWidth(),
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing.xxl,
      width: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: theme.spacing.m,
      width: "100%",
    },
    headerText: {
      fontSize: theme.fontSizes.l.fontSize,
      fontWeight: theme.fontWeights.semiBold,
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  return (
    <Modal animationType="fade" onRequestClose={onCancel} transparent visible={isVisible}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.header}>
            {typeof title === "string" ? <Text style={styles.headerText}>{title}</Text> : title}
            <Pressable onPress={onCancel}>
              <Image
                accessibilityLabel="Close"
                accessible
                resizeMode="contain"
                source={CloseIcon}
                style={{ height: 14, width: 14 }}
              />
            </Pressable>
          </View>
          <>
            {typeof body === "string" ? <Text style={{ width: "100%" }}>{body}</Text> : body}
            <View style={styles.footer}>
              {Some(customButton) ? (
                customButton
              ) : (
                <>
                  <View style={{ marginRight: theme.spacing.s }}>
                    <Button onPress={onCancel} text="No" type="secondary" />
                  </View>
                  <Button onPress={onConfirm} text="Yes" />
                </>
              )}
            </View>
          </>
        </View>
      </View>
    </Modal>
  );
};
