import { FlatList, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TextInput, useTheme } from "@merit/frontend-components";
import { debounce } from "../utils/Debounce";
import React, { useCallback, useState } from "react";

type Item = {
  name: string;
  value: string;
};

type Props = {
  disabled?: boolean;
  width?: number;
  label: string;
  onSelect: (option: Item) => void;
  placeholder: string;
  options: Item[];
  onSearch: (searchTerm: string) => void;
  value: string;
  onChange: (textInput: string) => void;
};

export const TypeaheadDropdown = ({
  disabled = false,
  label,
  onChange,
  onSearch,
  onSelect,
  options,
  placeholder,
  value,
  width = 250,
}: Props) => {
  const { theme } = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState<string>();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      margin: 1,
      width: "auto",
    },
    dropdown: {
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.default,
      borderRadius: 5,
      borderWidth: 1,
      marginTop: theme.spacing.s,
      maxHeight: 200,
      position: "absolute",
      top: 60,
      width,
    },
    dropdownScrollable: {
      overflow: "hidden",
    },
    highlightedItem: {
      backgroundColor: theme.colors.background.default,
    },
    item: {
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      padding: theme.spacing.m,
      width: "100%",
    },
    noResults: {
      alignItems: "center",
      padding: theme.spacing.m,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchInputQuery = useCallback(
    debounce((searchTerm: string) => {
      onSearch(searchTerm);
    }, 500),
    [],
  );

  const handleSelect = (item: Item) => {
    onChange(item.name);
    onSelect(item);
    setShowDropdown(false);
  };

  const handleInputChange = (text: string) => {
    onChange(text);
    if (text.length > 2) {
      searchInputQuery(text);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const renderItem = ({ item }: { item: Item }) => (
    <TouchableOpacity
      onLongPress={() => {
        setHoveredItemId(item.value);
      }}
      onPress={() => {
        handleSelect(item);
      }}
      onPressOut={() => {
        setHoveredItemId(undefined);
      }}
      style={[styles.item, item.value === hoveredItemId && styles.highlightedItem]}
    >
      <Text>{item.name}</Text>
    </TouchableOpacity>
  );

  const renderNoResults = () => (
    <View style={styles.noResults}>
      <Text>No Results</Text>
    </View>
  );

  const dropdownStyle =
    options.length > 5 ? styles.dropdown : [styles.dropdown, styles.dropdownScrollable];

  return (
    <View style={styles.container}>
      <View style={{ width }}>
        <TextInput
          disabled={disabled}
          label={label}
          leftIcon="searchSmallSubdued"
          onChangeText={handleInputChange}
          placeholder={placeholder}
          size="medium"
          value={value}
        />
      </View>
      {showDropdown && (
        <View style={[dropdownStyle, { borderWidth: value.length > 0 ? 1 : 0 }]}>
          {options.length > 0 && value.length > 0 && (
            <FlatList data={options} keyExtractor={item => item.value} renderItem={renderItem} />
          )}
          {options.length === 0 && value.length > 0 && renderNoResults()}
        </View>
      )}
    </View>
  );
};
