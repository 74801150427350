import { Button } from "../Button";
import { CustomConfirmModal } from "../CustomConfirmModal";
import { ErrorText } from "../ErrorText";
import { Loading } from "../Loading";
import { Modal, StyleSheet, Text, View } from "react-native";
import { None } from "../../utils/None";
import { RejectModal } from "../../screens/ReviewClaim/RejectModal";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { UnreachableCaseError } from "../../utils/UnreachableCaseError";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useTheme } from "@merit/frontend-components";
import { useUserStore } from "../../store/userStore";
import React, { useState } from "react";
import type { ClaimLookupProps } from "./type";
import type { GetClaimByMeritCsResponse } from "../../__generated__/api/ClaimRoute";

type ClaimStatus = GetClaimByMeritCsResponse["claim"]["status"];

const MINIMUM_CLAIM_AMOUNT_IN_DOLLARS_THAT_NEEDS_MULTIPLE_REVIEWS = 250;

export const UpdateClaimStatus = ({ claimDetails, onUpdate }: ClaimLookupProps) => {
  const meritCS = useUserStore(_ => _.meritCS);

  if (None(meritCS)) {
    throw new Error("Logged in user is not MeritCS");
  }

  const { claimClient } = useApi();
  const { errorHandler } = useDefaultErrorHandler();
  const { isDesktopOrLarger } = useDeviceSize();
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<ClaimStatus>(claimDetails.claim.status);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<ClaimStatus | undefined>(undefined);
  const [note, setNote] = useState<string>();
  const [showError, setShowError] = useState(false);

  const { available, pending } = claimDetails.amountInfo;
  const {
    amount: claimAmount,
    id: claimId,
    nonAceServiceProvider,
    serviceProvider,
  } = claimDetails.claim;
  const unspentAmount = available + pending;
  const hasInsufficientAmount = () => unspentAmount <= 0 || claimAmount > unspentAmount;
  const isNonAceServiceProvider = Some(nonAceServiceProvider);
  const isFauxServiceProvider = Some(serviceProvider)
    ? serviceProvider.isFauxServiceProvider
    : false;

  const styles = StyleSheet.create({
    buttonRow: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: theme.spacing.m,
      justifyContent: "flex-end",
      marginVertical: theme.spacing.l,
    },
    centeredView: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
      flex: 1,
      justifyContent: "center",
    },
    container: {
      backgroundColor: theme.colors.background.white,
      elevation: 1,
      paddingHorizontal: theme.spacing.xl,
      shadowColor: "#111",
      shadowOffset: { height: 1, width: 1 },
      shadowOpacity: 0.15,
      shadowRadius: 5,
    },
    modalView: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderRadius: 4,
      width: isDesktopOrLarger ? "45%" : "80%",
    },
    rejectModalContent: {
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
      width: "100%",
    },
  });

  const handleAction = (action: ClaimStatus) => {
    setModalAction(action);
    setShowModal(true);
  };

  type RejectClaimStatusParams = {
    readonly reason?: string;
    readonly description?: string;
  };

  const updateClaimStatus = async (reject?: RejectClaimStatusParams) => {
    if (None(claimId)) {
      throw new Error("Trying to update claim status without a valid claim ID");
    }

    if (None(modalAction) || None(status)) {
      throw new Error("Trying to update claim status without any proper claim status");
    }

    const isNoteAdded = Some(note) && note !== "";
    const rejectReason = reject?.reason ?? "";
    const rejectNote = reject?.description ?? "";

    if (isNoteAdded && note.trim() === "") {
      setShowError(true);

      return;
    }

    setIsLoading(true);
    try {
      const updatePayload = {
        fromStatus: status,
        ...(isNoteAdded && { note: note.trim() }),
        ...(rejectReason !== "" && {
          reason: rejectReason,
          ...(rejectNote !== "" && { note: rejectNote.trim() }),
        }),
        toStatus: modalAction,
      };

      const response = await claimClient.updateStatus(claimId, updatePayload);
      if (response.success) {
        onUpdate();
        showToast({ message: "Successfully updated the claim status", type: "success" });
        setStatus(modalAction);
        setNote("");
        setShowError(false);
        setShowModal(false);
      } else {
        showToast({ message: response.message, type: "danger" });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setShowError(false);
    }
  };

  const showApproveButton = () => {
    if (Some(serviceProvider)) {
      if (isFauxServiceProvider) {
        return true;
      }

      return false;
    }

    return true;
  };

  const renderButtons = () => {
    switch (status) {
      case "Accepted":
      case "Rejected":
        const destinationStatus =
          claimAmount < MINIMUM_CLAIM_AMOUNT_IN_DOLLARS_THAT_NEEDS_MULTIPLE_REVIEWS
            ? "Pending Single Review"
            : "Pending First Review";

        return (
          <View style={styles.buttonRow}>
            <View style={{ width: 210 }}>
              <Button
                disabled={
                  !meritCS.manageClaims || status === "Rejected"
                    ? hasInsufficientAmount()
                    : undefined
                }
                onPress={() => {
                  handleAction(destinationStatus);
                }}
                size="small"
                text={destinationStatus}
                type="secondary"
              />
            </View>
          </View>
        );

      case "Pending Single Review":
        if (None(serviceProvider) && None(nonAceServiceProvider)) {
          return null;
        }
        if (Some(serviceProvider) && serviceProvider.status !== "Active") {
          return (
            <ErrorText error="WARNING - This QESP is INACTIVE. Please turn QESP to ACTIVE to enable review actions for this claim." />
          );
        }

        return (
          <View style={styles.buttonRow}>
            {(isNonAceServiceProvider || !isFauxServiceProvider) && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageClaims}
                  onPress={() => {
                    handleAction("Pending Vendor Approval");
                  }}
                  size="small"
                  text="Passes Single Review"
                  type="secondary"
                />
              </View>
            )}
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Pending Escalation Review");
                }}
                size="small"
                text="Escalate"
                type="secondary"
              />
            </View>
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Rejected");
                }}
                size="small"
                text="Reject"
                type="destructive"
              />
            </View>
            {showApproveButton() && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageClaims}
                  onPress={() => {
                    handleAction("Accepted");
                  }}
                  size="small"
                  text="Approve"
                  type="primary"
                />
              </View>
            )}
          </View>
        );

      case "Pending First Review":
        if (None(serviceProvider) && None(nonAceServiceProvider)) {
          return null;
        }
        if (Some(serviceProvider) && serviceProvider.status !== "Active") {
          return (
            <ErrorText error="WARNING - This QESP is INACTIVE. Please turn QESP to ACTIVE to enable review actions for this claim." />
          );
        }

        return (
          <View style={styles.buttonRow}>
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Pending Second Review");
                }}
                size="small"
                text="Passes First Review"
                type="secondary"
              />
            </View>
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Pending Escalation Review");
                }}
                size="small"
                text="Escalate"
                type="secondary"
              />
            </View>
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Rejected");
                }}
                size="small"
                text="Reject"
                type="destructive"
              />
            </View>
            {(isNonAceServiceProvider || isFauxServiceProvider) && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageClaims}
                  onPress={() => {
                    handleAction("Accepted");
                  }}
                  size="small"
                  text="Approve"
                  type="primary"
                />
              </View>
            )}
          </View>
        );

      case "Pending Second Review":
        if (None(serviceProvider) && None(nonAceServiceProvider)) {
          return null;
        }
        if (Some(serviceProvider) && serviceProvider.status !== "Active") {
          return (
            <ErrorText error="WARNING - This QESP is INACTIVE. Please turn QESP to ACTIVE to enable review actions for this claim." />
          );
        }

        return (
          <View style={styles.buttonRow}>
            {(isNonAceServiceProvider || !isFauxServiceProvider) && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageClaims}
                  onPress={() => {
                    handleAction("Pending Vendor Approval");
                  }}
                  size="small"
                  text="Passes Second Review"
                  type="secondary"
                />
              </View>
            )}
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Pending Escalation Review");
                }}
                size="small"
                text="Escalate"
                type="secondary"
              />
            </View>
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageClaims}
                onPress={() => {
                  handleAction("Rejected");
                }}
                size="small"
                text="Reject"
                type="destructive"
              />
            </View>
            {showApproveButton() && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageClaims}
                  onPress={() => {
                    handleAction("Accepted");
                  }}
                  size="small"
                  text="Approve"
                  type="primary"
                />
              </View>
            )}
          </View>
        );

      case "Pending Escalation Review":
        if (None(serviceProvider) && None(nonAceServiceProvider)) {
          return null;
        }
        if (Some(serviceProvider) && serviceProvider.status !== "Active") {
          return (
            <ErrorText error="WARNING - This QESP is INACTIVE. Please turn QESP to ACTIVE to enable review actions for this claim." />
          );
        }

        return (
          <View style={styles.buttonRow}>
            {(isNonAceServiceProvider || !isFauxServiceProvider) && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageEscalatedClaims}
                  onPress={() => {
                    handleAction("Pending Vendor Approval");
                  }}
                  size="small"
                  text="Passes Internal Review"
                  type="secondary"
                />
              </View>
            )}
            <View style={{ width: 210 }}>
              <Button
                disabled={!meritCS.manageEscalatedClaims}
                onPress={() => {
                  handleAction("Rejected");
                }}
                size="small"
                text="Reject"
                type="destructive"
              />
            </View>
            {showApproveButton() && (
              <View style={{ width: 210 }}>
                <Button
                  disabled={hasInsufficientAmount() || !meritCS.manageEscalatedClaims}
                  onPress={() => {
                    handleAction("Accepted");
                  }}
                  size="small"
                  text="Approve"
                  type="primary"
                />
              </View>
            )}
          </View>
        );

      default:
        return null;
    }
  };

  const renderRejectModal = () => (
    <Modal
      animationType="fade"
      onRequestClose={() => {
        setShowModal(false);
      }}
      transparent
      visible={showModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.rejectModalContent}>
            <RejectModal
              onCancel={() => {
                setShowModal(false);
              }}
              onReject={reject => {
                updateClaimStatus(reject);
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );

  const renderConfirmModal = (destinationStatus: ClaimStatus) => {
    switch (destinationStatus) {
      case "Pending Internal Review":
      case "Pending Single Review":
      case "Pending First Review":
      case "Pending Second Review":
        return (
          <CustomConfirmModal
            body={
              <View style={{ width: "100%" }}>
                <Text>
                  You're changing the status of the Claim ID {claimId} from {status} to{" "}
                  {destinationStatus}.
                </Text>
                <View style={{ marginTop: 24 }}>
                  <Text style={{ marginBottom: 6 }}>Type the reason to proceed</Text>
                  <TextInput
                    onChangeText={setNote}
                    placeholder="Reason for the status change"
                    value={note}
                  />
                  {showError && <ErrorText error="Please provide a reason for status change" />}
                </View>
              </View>
            }
            customButton={
              <>
                <View style={{ marginRight: 8 }}>
                  <Button
                    onPress={() => {
                      setNote("");
                      setShowModal(false);
                    }}
                    text="No"
                    type="secondary"
                  />
                </View>
                <Button
                  disabled={None(note) || note === ""}
                  onPress={() => {
                    updateClaimStatus();
                  }}
                  text="Yes"
                />
              </>
            }
            isVisible={showModal}
            onCancel={() => {
              setNote("");
              setShowModal(false);
            }}
            onConfirm={() => {
              updateClaimStatus();
            }}
            title="Are you sure?"
          />
        );

      case "Pending Escalation Review":
        return (
          <CustomConfirmModal
            body="Are you sure you want to escalate this claim?"
            customButton={
              <>
                <View style={{ marginRight: 8 }}>
                  <Button
                    onPress={() => {
                      setShowModal(false);
                    }}
                    text="Cancel"
                    type="secondary"
                  />
                </View>
                <Button
                  onPress={() => {
                    updateClaimStatus();
                  }}
                  text="Escalate"
                />
              </>
            }
            isVisible={showModal}
            onCancel={() => {
              setShowModal(false);
            }}
            onConfirm={() => {
              updateClaimStatus();
            }}
            title="Escalate this claim?"
          />
        );

      case "Pending Vendor Approval":
        return (
          <CustomConfirmModal
            body="Are you sure you want to send this claim for vendor approval review?"
            customButton={
              <>
                <View style={{ marginRight: 8 }}>
                  <Button
                    onPress={() => {
                      setShowModal(false);
                    }}
                    text="Cancel"
                    type="secondary"
                  />
                </View>
                <Button
                  onPress={() => {
                    updateClaimStatus();
                  }}
                  text="Send for vendor approval review"
                />
              </>
            }
            isVisible={showModal}
            onCancel={() => {
              setShowModal(false);
            }}
            onConfirm={() => {
              updateClaimStatus();
            }}
            title="Send this claim for vendor approval review"
          />
        );

      case "Accepted":
        return (
          <CustomConfirmModal
            body="Are you sure you want to approve this claim?"
            customButton={
              <>
                <View style={{ marginRight: 8 }}>
                  <Button
                    onPress={() => {
                      setShowModal(false);
                    }}
                    text="Cancel"
                    type="secondary"
                  />
                </View>
                <Button
                  onPress={() => {
                    updateClaimStatus();
                  }}
                  text="Approve"
                />
              </>
            }
            isVisible={showModal}
            onCancel={() => {
              setShowModal(false);
            }}
            onConfirm={() => {
              updateClaimStatus();
            }}
            title="Approve this claim?"
          />
        );

      case "Rejected":
        return renderRejectModal();

      default:
        throw new UnreachableCaseError(destinationStatus);
    }
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <View style={{ minWidth: 160 }}>
          <Loading />
        </View>
      ) : (
        <>
          {renderButtons()}
          {Some(modalAction) ? renderConfirmModal(modalAction) : null}
        </>
      )}
    </View>
  );
};
