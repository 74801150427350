import { Button, useTheme } from "@merit/frontend-components";
import { ClaimStatsCard } from "../../components/MeritCS/ClaimStatsCard";
import { ClaimsList } from "../../components/ClaimsList";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { StyleSheet, View } from "react-native";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import axios from "axios";
import type {
  GetClaimStatsByMeritCsResponse,
  GetReviewClaimsResponse,
} from "../../__generated__/api/ClaimRoute";

export const ReviewClaimsScreen = () => {
  const { theme } = useTheme();
  const { claimClient } = useApi();
  const { errorHandler } = useDefaultErrorHandler();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  });

  const [claimStats, setClaimsStats] = useState<GetClaimStatsByMeritCsResponse>();
  const [reviewClaims, setReviewClaims] = useState<GetReviewClaimsResponse["reviewClaims"]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getClaimStatsAndClaimsToReview = useCallback(async () => {
    setIsLoading(true);
    try {
      const [{ reviewClaims: reviewClaimsResponse }, claimStatsResponse] = await Promise.all([
        claimClient.getReviewClaims(),
        claimClient.getClaimStatsByMeritCs(),
      ]);

      setReviewClaims(reviewClaimsResponse);
      setClaimsStats(claimStatsResponse);
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  }, [claimClient, errorHandler]);

  useEffect(() => {
    getClaimStatsAndClaimsToReview();
  }, [claimClient, errorHandler, getClaimStatsAndClaimsToReview]);

  const assignReviewClaims = async () => {
    setIsLoading(true);
    try {
      await claimClient.assignReviewClaims();
      await getClaimStatsAndClaimsToReview();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        showToast({ duration: 3000, message: error.response?.data, type: "danger" });
      } else {
        errorHandler(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const refreshOnVisibility = () => {
      if (document.visibilityState === "visible") {
        getClaimStatsAndClaimsToReview();
      }
    };

    document.addEventListener("visibilitychange", refreshOnVisibility);

    return () => {
      document.removeEventListener("visibilitychange", refreshOnVisibility);
    };

    // The dependency array is kept empty to avoid unnecessary reinitialization of the event listeners.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || None(claimStats)) {
    return (
      <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
        <Loading />
      </View>
    );
  }

  const claimStatsData = [
    { label: "Claims needing a single review", value: claimStats.totalSingleReviewClaims },
    { label: "Claims needing first review", value: claimStats.totalFirstReviewClaims },
    { label: "Claims needing 2nd review", value: claimStats.totalSecondReviewClaims },
    { label: "Escalated claims", value: claimStats.totalEscalatedClaims },
    { label: "Claims submitted", value: claimStats.totalSubmittedClaims },
  ];

  return (
    <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
      <View style={styles.container}>
        {claimStatsData.map(claimStat => (
          <ClaimStatsCard key={claimStat.label} label={claimStat.label} value={claimStat.value} />
        ))}
      </View>

      {Some(reviewClaims) && reviewClaims.length !== 0 && (
        <View style={{ display: "flex", marginTop: theme.spacing.xl }}>
          <ClaimsList claims={reviewClaims} />
        </View>
      )}

      {Some(reviewClaims) && reviewClaims.length === 0 && (
        <View style={{ alignItems: "center", display: "flex", marginTop: theme.spacing.xxl }}>
          <Button onPress={assignReviewClaims} size="small" text="Get claims to review" />
        </View>
      )}
    </View>
  );
};
