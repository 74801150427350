/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { RejectClaimPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class ReviewClaim<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetClaim
   * @request GET:/api/review-claim/{token}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getClaim = async (
    token: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      amountInfo: {
        available: number;
        pending: number;
        spent: number;
      };
      claim: {
        amount: number;
        category: string;
        child: {
          firstName: string;
          lastName: string;
          schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
        };
        dateOfService: string;
        description?: string;
        files: {
          detectedFileExt: string;
          filename: string;
          receiptNumber?: string;
          url: string;
        }[];
        id: number;
        internalReviewLink?: string;
        isFauxServiceProvider?: boolean;
        nonAceServiceProvider?: {
          address: string;
          contactName?: string;
          contactTitle?: string;
          email: string;
          name: string;
          phoneNumber: string;
          website: string;
        };
        parent: {
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          lastName: string;
        };
        payTo: string;
        physicalGood?: boolean;
        serviceProvider?: {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          isFauxServiceProvider: boolean;
          name: string;
          number: string;
          status: "Active" | "Inactive" | ("Active" & "Inactive");
        };
        serviceProviderNumber?: string;
        status:
          | "Pending Internal Review"
          | "Pending Single Review"
          | "Pending First Review"
          | "Pending Second Review"
          | "Pending Escalation Review"
          | "Pending Vendor Approval"
          | "Accepted"
          | "Rejected"
          | ("Pending Internal Review" &
              "Pending Single Review" &
              "Pending First Review" &
              "Pending Second Review" &
              "Pending Escalation Review" &
              "Pending Vendor Approval" &
              "Accepted" &
              "Rejected");
        uuid: string;
      };
      relevantClaims: {
        amount: number;
        child: {
          firstName: string;
          lastName: string;
        };
        createdAt: string;
        id: number;
        internalReviewLink: string;
        serviceProviderNumber?: string;
        status:
          | "Pending Internal Review"
          | "Pending Single Review"
          | "Pending First Review"
          | "Pending Second Review"
          | "Pending Escalation Review"
          | "Pending Vendor Approval"
          | "Accepted"
          | "Rejected"
          | ("Pending Internal Review" &
              "Pending Single Review" &
              "Pending First Review" &
              "Pending Second Review" &
              "Pending Escalation Review" &
              "Pending Vendor Approval" &
              "Accepted" &
              "Rejected");
        uuid: string;
      }[];
      reviewType: string;
      reviewed: boolean;
    }>
  > => {
    try {
      const response = await this.request<
        {
          amountInfo: {
            available: number;
            pending: number;
            spent: number;
          };
          claim: {
            amount: number;
            category: string;
            child: {
              firstName: string;
              lastName: string;
              schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
            };
            dateOfService: string;
            description?: string;
            files: {
              detectedFileExt: string;
              filename: string;
              receiptNumber?: string;
              url: string;
            }[];
            id: number;
            internalReviewLink?: string;
            isFauxServiceProvider?: boolean;
            nonAceServiceProvider?: {
              address: string;
              contactName?: string;
              contactTitle?: string;
              email: string;
              name: string;
              phoneNumber: string;
              website: string;
            };
            parent: {
              firstName: string;
              hasHealthyBankConnection: boolean;
              hasProvidedBankDetails: boolean;
              lastName: string;
            };
            payTo: string;
            physicalGood?: boolean;
            serviceProvider?: {
              hasHealthyBankConnection: boolean;
              hasProvidedBankDetails: boolean;
              isFauxServiceProvider: boolean;
              name: string;
              number: string;
              status: "Active" | "Inactive" | ("Active" & "Inactive");
            };
            serviceProviderNumber?: string;
            status:
              | "Pending Internal Review"
              | "Pending Single Review"
              | "Pending First Review"
              | "Pending Second Review"
              | "Pending Escalation Review"
              | "Pending Vendor Approval"
              | "Accepted"
              | "Rejected"
              | ("Pending Internal Review" &
                  "Pending Single Review" &
                  "Pending First Review" &
                  "Pending Second Review" &
                  "Pending Escalation Review" &
                  "Pending Vendor Approval" &
                  "Accepted" &
                  "Rejected");
            uuid: string;
          };
          relevantClaims: {
            amount: number;
            child: {
              firstName: string;
              lastName: string;
            };
            createdAt: string;
            id: number;
            internalReviewLink: string;
            serviceProviderNumber?: string;
            status:
              | "Pending Internal Review"
              | "Pending Single Review"
              | "Pending First Review"
              | "Pending Second Review"
              | "Pending Escalation Review"
              | "Pending Vendor Approval"
              | "Accepted"
              | "Rejected"
              | ("Pending Internal Review" &
                  "Pending Single Review" &
                  "Pending First Review" &
                  "Pending Second Review" &
                  "Pending Escalation Review" &
                  "Pending Vendor Approval" &
                  "Accepted" &
                  "Rejected");
            uuid: string;
          }[];
          reviewType: string;
          reviewed: boolean;
        },
        {
          error: string;
        }
      >({
        path: `/api/review-claim/${token}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name ApproveClaim
   * @request POST:/api/review-claim/{token}/approve
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  approveClaim = async (
    token: string,
    body: {
      reviewerName: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/review-claim/${token}/approve`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SendForExternalReview
   * @request POST:/api/review-claim/{token}/send-for-external-review
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  sendForExternalReview = async (
    token: string,
    body: {
      reviewerName: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/review-claim/${token}/send-for-external-review`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name RejectClaim
   * @request POST:/api/review-claim/{token}/reject
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  rejectClaim = async (
    token: string,
    body: RejectClaimPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/review-claim/${token}/reject`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name AssignServiceProvider
   * @request POST:/api/review-claim/{token}/assign-service-provider
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  assignServiceProvider = async (
    token: string,
    body: {
      serviceProviderNumber: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/review-claim/${token}/assign-service-provider`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
