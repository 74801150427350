import { Some } from "./Some";
import { Toast } from "react-native-toast-notifications";

type ShowToastParams = {
  message: string;
  onClose?: () => void;
  type?: "danger" | "normal" | "success" | "warning";
  duration?: number;
};

export const showToast = ({
  duration = 1500,
  message,
  onClose,
  type = "success",
}: ShowToastParams) => {
  Toast.show(message, {
    duration,
    onClose: () => {
      if (Some(onClose)) {
        onClose();
      }
    },
    placement: "top",
    type,
  });
};
