import { ClaimsList } from "../../components/ClaimsList";
import { Loading } from "../../components/Loading";
import { Pagination } from "../../components/Pagination/Pagination";
import { Some } from "../../utils/Some";
import { View } from "react-native";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import type { ColumnKeys } from "../../components/ClaimsList/ClaimsList";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { MeritCSDrawerRouteParams } from "../../navigation";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteProp } from "@react-navigation/native";
import type { SortDirection } from "../../components/Table/types";

type PaginationState = {
  readonly pageNumber: number;
  readonly limit: number;
  readonly total: number;
  readonly totalPages: number;
};

export const EscalatedClaimsScreen = () => {
  const { theme } = useTheme();
  const { claimClient } = useApi();
  const { errorHandler } = useDefaultErrorHandler();
  const [claims, setClaims] = useState<GetClaimsResponse["claims"]>([]);
  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();
  const { params } = useRoute<RouteProp<MeritCSDrawerRouteParams, "MeritCSEscalatedClaims">>();

  const pageNumberFromRouteParams = Some(params) && Some(params.pageNumber) ? params.pageNumber : 1;
  const sortByFromRouteParams = Some(params.sortBy) ? params.sortBy : "id";
  const sortOrderFromRouteParams = Some(params.sortOrder) ? params.sortOrder : "Ascending";

  const [paginationState, setPaginationState] = useState<PaginationState>({
    limit: 25,
    pageNumber: pageNumberFromRouteParams,
    total: 0,
    totalPages: 0,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  type SortParams = {
    sortBy: ColumnKeys;
    sortOrder: SortDirection;
  };

  const onSort = ({ sortBy, sortOrder }: SortParams) => {
    navigation.navigate("MeritCSEscalatedClaims", {
      pageNumber: 1,
      sortBy,
      sortOrder: sortOrder === "Ascending" ? "Descending" : "Ascending",
    });
  };

  const getClaims = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await claimClient.getClaims({
        limit: paginationState.limit,
        page: paginationState.pageNumber,
        sortBy: sortByFromRouteParams,
        sortOrder: sortOrderFromRouteParams === "Ascending" ? "asc" : "desc",
        statuses: "Pending Escalation Review",
      });
      if (response.success) {
        setClaims(response.data.claims);
        setPaginationState(prev => ({
          ...prev,
          total: response.data.total,
          totalPages: response.data.totalPages,
        }));
      } else {
        showToast({ message: response.message, type: "danger" });
      }
    } catch (error: unknown) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    claimClient,
    errorHandler,
    paginationState.limit,
    paginationState.pageNumber,
    sortByFromRouteParams,
    sortOrderFromRouteParams,
  ]);

  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    const refreshClaimsOnVisibility = () => {
      if (document.visibilityState === "visible") {
        getClaims();
      }
    };

    document.addEventListener("visibilitychange", refreshClaimsOnVisibility);

    return () => {
      document.removeEventListener("visibilitychange", refreshClaimsOnVisibility);
    };
  }, [getClaims]);

  return (
    <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
      {isLoading ? (
        <Loading />
      ) : (
        <ClaimsList
          claims={claims}
          defaultSort={{ direction: sortOrderFromRouteParams, key: sortByFromRouteParams }}
          onSort={onSort}
        />
      )}
      {claims.length > 0 && (
        <Pagination
          currentPage={paginationState.pageNumber}
          onPageChange={(pageNumber: number) => {
            navigation.navigate("MeritCSEscalatedClaims", {
              pageNumber,
              sortBy: sortByFromRouteParams,
              sortOrder: sortOrderFromRouteParams,
            });
            setPaginationState(prev => ({
              ...prev,
              pageNumber,
            }));
          }}
          pageSize={paginationState.limit}
          totalCount={paginationState.total}
          totalPages={paginationState.totalPages}
        />
      )}
    </View>
  );
};
