import { AssignServiceProvider } from "./AssignServiceProvider";
import { Collapse, useTheme } from "@merit/frontend-components";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { NonQespDetails } from "./NonQespDetails";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { UpdateClaimAmount } from "./UpdateClaimAmount";
import { UpdateClaimPayTo } from "./UpdateClaimPayTo";
import { formatCurrency } from "../../utils/FormatHelper";
import InformationIcon from "../../../assets/icons/information.png";
import InformationOutlineIcon from "../../../assets/icons/information_outlined_m_default.png";
import React, { useState } from "react";
import dayjs from "dayjs";
import type { GetClaimByMeritCsResponse } from "../../__generated__/api/ClaimRoute";

type Props = {
  readonly claimDetails: GetClaimByMeritCsResponse;
  readonly onUpdate: () => void;
};

export const ClaimDetails = ({ claimDetails, onUpdate }: Props) => {
  const { theme } = useTheme();
  const [showDateOfServiceTooltip, setShowDateOfServiceTooltip] = useState(false);

  const { available, pending } = claimDetails.amountInfo;
  const unspentAmount = available + pending;
  const hasInsufficientAmount = () =>
    available < 0 || unspentAmount <= 0 || claimDetails.claim.amount > unspentAmount;
  const [latestReviewEvent] = [...claimDetails.claimReviewEvents].sort((a, b) => b.id - a.id);

  const styles = StyleSheet.create({
    accordionTitleWrapper: {
      flexDirection: "row",
      paddingBottom: 3.5,
      paddingTop: 3.5,
      paddingVertical: theme.spacing.l,
    },
    accordionWrapper: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      height: "auto",
      padding: 0,
    },
    contentWrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      margin: theme.spacing.xxl,
    },
    infoIconWrapper: {
      marginLeft: theme.spacing.s,
      marginRight: 0,
    },
    nonAceServiceProvider: {
      backgroundColor: theme.colors.background.default,
      flexDirection: "column",
      paddingHorizontal: 50,
    },
    rejectReasonContainer: {
      borderTopColor: theme.colors.background.default,
      borderTopWidth: 1,
      marginTop: theme.spacing.s,
      paddingTop: theme.spacing.s,
    },
    row: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: 40,
      paddingVertical: theme.spacing.l,
    },
    tooltip: {
      backgroundColor: theme.colors.brand.oceanBlue,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      bottom: theme.spacing.xxl,
      elevation: theme.elevations.depth1?.elevation,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.l,
      position: "absolute",
      right: theme.spacing.l,
      shadowColor: theme.colors.border.shadow.subdued,
      shadowRadius: theme.borderRadii.s,
      width: 220,
    },
  });

  const {
    amount,
    category,
    child: { firstName, lastName, schoolYear },
    dateOfService,
    description,
    files,
    nonAceServiceProvider,
    parent,
    payTo,
    physicalGood,
    serviceProvider: qesp,
    status,
  } = claimDetails.claim;

  return (
    <>
      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Claim status</Text>
        </View>
        <View style={{ flex: 2 }}>
          <View>
            <Text>{status}</Text>
            {status === "Rejected" && Some(latestReviewEvent) && (
              <View style={styles.rejectReasonContainer}>
                <Text style={{ color: theme.colors.text.alert.critical }}>
                  Reason: {latestReviewEvent.rejectionReason}
                </Text>
                {Some(latestReviewEvent.rejectionNotes) && (
                  <Text
                    style={{ color: theme.colors.text.alert.critical, marginTop: theme.spacing.xs }}
                  >
                    Note: {latestReviewEvent.rejectionNotes}
                  </Text>
                )}
              </View>
            )}
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Student</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>{`${firstName} ${lastName}`}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Parent/Guardian</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>
            {parent.firstName} {parent.lastName}
          </Text>
        </View>
      </View>

      {Some(qesp?.name) && (
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Service provider name</Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text>{qesp.name}</Text>
          </View>
        </View>
      )}

      {Some(qesp?.number) && (
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Service provider number</Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text>{qesp.number}</Text>
          </View>
        </View>
      )}

      {None(qesp?.number) && Some(nonAceServiceProvider) && (
        <View style={styles.accordionWrapper}>
          <Collapse
            children={
              <View style={styles.nonAceServiceProvider}>
                <NonQespDetails nonQespDetails={nonAceServiceProvider} />
                <AssignServiceProvider claimId={claimDetails.claim.id} onUpdate={onUpdate} />
              </View>
            }
            renderHeader={
              <View style={styles.accordionTitleWrapper}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Non-QESP details</Text>
                </View>
              </View>
            }
            type="custom"
          />
        </View>
      )}

      <View
        style={[
          styles.row,
          {
            ...(None(qesp?.number) &&
              Some(nonAceServiceProvider) && {
                borderTopColor: theme.colors.border.subdued,
                borderTopWidth: 1,
              }),
          },
        ]}
      >
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Service category</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>{category}</Text>
        </View>
      </View>

      {Some(description) && (
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: theme.fontWeights.semiBold }}>
              What educational benefits did your child receive?
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text>{description}</Text>
          </View>
        </View>
      )}

      {Some(physicalGood) && (
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: theme.fontWeights.semiBold }}>
              Is this for a physical good?
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text>{physicalGood ? "Yes" : "No"}</Text>
          </View>
        </View>
      )}

      <View style={styles.row}>
        <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Date of Service</Text>
          <Pressable
            onPress={() => {
              setShowDateOfServiceTooltip(prevState => !prevState);
            }}
            style={styles.infoIconWrapper}
          >
            <Image
              accessible
              source={showDateOfServiceTooltip ? InformationIcon : InformationOutlineIcon}
              style={{
                height: 20,
                width: 20,
              }}
            />
          </Pressable>

          {showDateOfServiceTooltip && (
            <View style={styles.tooltip}>
              <Text style={{ color: theme.colors.background.white, marginBottom: 16 }}>
                If the Year is for 2021-2022, services can be approved starting on 4/25/2022 and
                onward.
              </Text>
              <Text style={{ color: theme.colors.background.white, marginBottom: 16 }}>
                If the Year is for 2022-2023, services can be approved starting on 7/1/2022 and
                onward.
              </Text>
              <Text style={{ color: theme.colors.background.white }}>
                If the Year is for 2023-2024, services can be approved starting on 7/1/2023 and
                onward.
              </Text>
            </View>
          )}
        </View>
        <View style={{ flex: 2 }}>
          <Text>{dayjs(dateOfService).format("MM/DD/YYYY")}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Claim year</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>{Some(schoolYear) ? `${schoolYear} - ${schoolYear + 1}` : "NA"}</Text>
        </View>
      </View>

      <View style={styles.accordionWrapper}>
        <Collapse
          children={
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }} />
              <View style={{ flex: 2 }}>
                <UpdateClaimAmount claimDetails={claimDetails} onUpdate={onUpdate} />
              </View>
            </View>
          }
          renderHeader={
            <View style={styles.accordionTitleWrapper}>
              <View style={{ flex: 1 }}>
                <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Claim amount</Text>
              </View>
              <View style={{ flex: 2, marginLeft: -27 }}>
                <Text>{formatCurrency(amount)}</Text>
              </View>
            </View>
          }
          type="custom"
        />
      </View>

      <View style={styles.accordionWrapper}>
        <Collapse
          children={
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }} />
              <View style={{ flex: 2 }}>
                <UpdateClaimPayTo claimDetails={claimDetails} onUpdate={onUpdate} />
              </View>
            </View>
          }
          renderHeader={
            <View
              style={{
                flexDirection: "row",
                paddingBottom: 3.5,
                paddingTop: 3.5,
                paddingVertical: theme.spacing.l,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Deliver funds to</Text>
              </View>
              <View style={{ flex: 2, marginLeft: -27 }}>
                <Text>{payTo === "User" ? "Student (Parent/Guardian)" : "Service Provider"}</Text>
              </View>
            </View>
          }
          type="custom"
        />
      </View>

      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>
            Account/invoice/receipt number
          </Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>
            {files
              .filter(_ => Some(_.receiptNumber))
              .map(_ => _.receiptNumber)
              .join(", ")}
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Receipt or Invoice</Text>
        </View>
        <View style={{ flex: 2 }}>
          {files.map(file => (
            <Pressable
              key={file.filename}
              onPress={() => {
                window.open(file.url);
              }}
              style={{ marginBottom: 20 }}
            >
              {file.detectedFileExt === "pdf" ? (
                <Text style={{ color: theme.colors.brand.oceanBlue }}>{file.filename}</Text>
              ) : (
                <Image source={{ uri: file.url }} style={{ height: 200, width: 200 }} />
              )}
            </Pressable>
          ))}
        </View>
      </View>

      <View style={[styles.row, { borderBottomWidth: 0 }]}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Remaining funds</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text style={hasInsufficientAmount() && { color: "red" }}>
            {formatCurrency(unspentAmount)}
          </Text>
        </View>
      </View>
    </>
  );
};
