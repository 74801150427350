import { ExternalLink } from "../../components/ExternalLink";
import { Image, StyleSheet, Text, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import Lock from "../../../assets/icons/lock.png";
import React from "react";

export function InsufficientPermissions() {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
  });

  return (
    <View>
      <View style={styles.container}>
        <View style={{ alignItems: "center", paddingVertical: theme.spacing.xxl }}>
          <Image source={Lock} style={{ height: 110, width: 110 }} />
        </View>
        <View style={{ paddingVertical: theme.spacing.xl }}>
          <Text
            style={{
              fontSize: theme.fontSizes.xl.fontSize,
              fontWeight: theme.fontWeights.semiBold,
            }}
          >
            It looks like you cannot access this feature
          </Text>
        </View>
        <Text>
          If you think this is a mistake, please reach out to{" "}
          <ExternalLink text="help@aceohio.org" url="mailto:help@aceohio.org" />
        </Text>
      </View>
    </View>
  );
}
