// Copyright 2023 Merit International Inc. All Rights Reserved

import { BankAlreadyLinkedScreen } from "../../screens/BankAlreadyLinkedScreen";
import { BankLinkFailureScreen } from "../../screens/BankLinkFailureScreen";
import { BankLinkSuccessScreen } from "../../screens/BankLinkSuccessScreen";
import { ClaimApprovedScreen } from "../../screens/ReviewClaim/ClaimApprovedScreen";
import { ClaimRejectedScreen } from "../../screens/ReviewClaim/ClaimRejectedScreen";
import { Dashboard } from "../../screens/Dashboard";
import { NotEligibleScreen } from "../../screens/NotEligibleScreen";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { LinkBankAccountScreen as ParentLinkBankAccountScreen } from "../../screens/Parent/LinkBankAccountScreen";
import { SentForExternalReviewScreen } from "../../screens/ReviewClaim/SentForExternalReviewScreen";
import { ServiceProvider } from "../../screens/ServiceProvider";
import { Some } from "../../utils/Some";
import { SubmitNewClaim } from "../../screens/SubmitNewClaim";
import { UnauthenticatedReviewClaimScreen } from "../../screens/ReviewClaim/UnauthenticatedReviewClaimScreen";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useUserStore } from "../../store/userStore";
import React from "react";
import type { ParentRouteParams } from "..";

const ParentWebRootStack = createNativeStackNavigator<ParentRouteParams>();

export const ParentWebRootStackNavigator = () => {
  const parent = useUserStore(_ => _.parent);
  const isParentEligible = Some(parent) && parent.children.length > 0;
  if (!isParentEligible) {
    return (
      <ParentWebRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
        <ParentWebRootStack.Screen component={NotEligibleScreen} name="NotEligible" />
      </ParentWebRootStack.Navigator>
    );
  }

  return (
    <ParentWebRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
      <ParentWebRootStack.Screen component={Dashboard} name="Dashboard" />
      <ParentWebRootStack.Screen component={SubmitNewClaim} name="SubmitNewClaim" />
      <ParentWebRootStack.Screen component={ServiceProvider} name="ServiceProvider" />
      <ParentWebRootStack.Screen
        component={BankAlreadyLinkedScreen}
        name="BankAlreadyLinkedScreen"
      />
      {(!parent.hasProvidedBankDetails || !parent.hasHealthyBankConnection) && (
        <ParentWebRootStack.Screen
          component={ParentLinkBankAccountScreen}
          name="ParentLinkBankAccountScreen"
        />
      )}
      <ParentWebRootStack.Screen component={NotFound} name="NotFound" />
      <ParentWebRootStack.Screen component={BankLinkSuccessScreen} name="BankLinkSuccess" />
      <ParentWebRootStack.Screen component={BankLinkFailureScreen} name="BankLinkFailure" />
      <ParentWebRootStack.Screen
        component={UnauthenticatedReviewClaimScreen}
        name="UnauthenticatedReviewClaim"
      />
      <ParentWebRootStack.Screen component={ClaimApprovedScreen} name="ClaimApproved" />
      <ParentWebRootStack.Screen component={ClaimRejectedScreen} name="ClaimRejected" />
      <ParentWebRootStack.Screen
        component={SentForExternalReviewScreen}
        name="SentForExternalReview"
      />
    </ParentWebRootStack.Navigator>
  );
};
