import { Card, useTheme } from "@merit/frontend-components";
import { StyleSheet, Text } from "react-native";

type Props = {
  label: string;
  value: number;
};

export const ClaimStatsCard = ({ label, value }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    cardContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.surface.hovered,
      display: "flex",
      height: 100,
      justifyContent: "center",
      margin: theme.spacing.s,
      padding: theme.spacing.s,
      width: 220,
    },
    claimStatsLabel: {
      ...theme.fontSizes.s,
      fontWeight: theme.fontWeights.normal,
      marginTop: theme.spacing.l,
    },
    claimStatsValue: {
      ...theme.fontSizes.xl,
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  return (
    <Card style={styles.cardContainer}>
      <Text style={styles.claimStatsValue}>{value.toLocaleString()}</Text>
      <Text style={styles.claimStatsLabel}>{label}</Text>
    </Card>
  );
};
