// Copyright 2023 Merit International Inc. All Rights Reserved

import { ClaimApprovedScreen } from "../../screens/ReviewClaim/ClaimApprovedScreen";
import { ClaimRejectedScreen } from "../../screens/ReviewClaim/ClaimRejectedScreen";
import { MeritCSLogin } from "../../screens/MeritCSLogin";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { SplashScreen as ParentLoginScreen } from "../../screens/SplashScreen";
import { SentForExternalReviewScreen } from "../../screens/ReviewClaim/SentForExternalReviewScreen";
import { ServiceProviderLogin } from "../../screens/ServiceProviderLogin";
import { UnauthenticatedReviewClaimScreen } from "../../screens/ReviewClaim/UnauthenticatedReviewClaimScreen";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import type { PreLoginRouteParams } from "..";

const PreLoginRootStack = createNativeStackNavigator<PreLoginRouteParams>();

export const PreLoginRootStackNavigator = () => (
  <PreLoginRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
    <PreLoginRootStack.Screen component={ParentLoginScreen} name="Splash" />
    <PreLoginRootStack.Screen component={NotFound} name="NotFound" />
    <PreLoginRootStack.Screen
      component={UnauthenticatedReviewClaimScreen}
      name="UnauthenticatedReviewClaim"
    />
    <PreLoginRootStack.Screen component={ClaimApprovedScreen} name="ClaimApproved" />
    <PreLoginRootStack.Screen component={ClaimRejectedScreen} name="ClaimRejected" />
    <PreLoginRootStack.Screen
      component={SentForExternalReviewScreen}
      name="SentForExternalReview"
    />
    <PreLoginRootStack.Screen component={ServiceProviderLogin} name="ServiceProviderLogin" />
    <PreLoginRootStack.Screen component={MeritCSLogin} name="MeritCSLogin" />
  </PreLoginRootStack.Navigator>
);
